<div class="track-card-style">
    <div class="name-style"> <span>{{track?.name}}</span> 
        <span *ngIf="track.isFavorite" class="fav-icn"></span>
        <span *ngIf="!track.isFavorite" class="fav-icn"></span>
    </div>
    <div class="tag-items">
        <a href="#" [class.disabled]="isAnchorDisable" *ngIf="track?.trackLength" class="btn common-button sector-style"><span>{{track?.trackLength | number:'1.2-2'}} Km</span></a>
        <a href="#" [class.disabled]="isAnchorDisable" *ngIf="track?.noOfSectors" class="btn common-button sector-style"><span>{{track?.noOfSectors}} {{'CP_LM_trackDetailsPage_sectors' | customTranslation: translationData}}</span></a>
    </div>
    <div class="owner-info">
        <span class="prof-image"></span> {{'CP_LM_MainPage_by' | customTranslation: translationData}} {{ track?.owner?.nickName ? track?.owner?.nickName : track?.owner?.name}} 
        <ng-container>
            <span class="dot"></span>
            <span class="fav-icn place-icn"></span>
            <span>{{track.favoriteCount}}</span>
        </ng-container>       
        <!-- <ng-container>
            <span class="dot"></span>
            <span class="rec-icn place-icn"></span>
            <span>24</span>
        </ng-container> -->
    </div>
</div>