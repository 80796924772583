import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppServicesService } from 'src/app/services/app-services.service';
import { CommonModalServiceService } from 'src/app/services/common-modal-service.service';
import { ToastService, ToastType } from 'src/app/services/toast.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  constructor(
    private commonModalService: CommonModalServiceService,
    private fb: FormBuilder,
    public _appService: AppServicesService,
    public toastService: ToastService,
    public translationService: TranslationService
  ) { }

  public editProfileForm !: FormGroup;
  @ViewChild('editPopup') editPopup: TemplateRef<any> | undefined;
  @Output() public cancelClicked = new EventEmitter<any>();
  showEditPopup: boolean = true;
  isHeaderVisible: boolean = true;
  isCloseModal: boolean = false;
  showModalContent: boolean = true;
  profileName: string = '';
  popupType: string = 'large-popup'
  profileEmail: string = '';
  defaultBanner = "../../../assets/mercedes.png";
  profileImage = "";
  profileInitials: string = '';
  ciamId = '';
  isShowToast = false;
  toastMessage: any;
  toastType: any;
  position: any;
  leftPosition: any;
  translationData = [];
  getProfileResponse: boolean = false;
  
  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.commonModalService._modalHeaderIsCloseBtnVisible.next(true);
    this.commonModalService._modalHeaderIsSaveBtnVisible.next(true);
    this.commonModalService._modalHeaderTitle.next('My Profile');
    this._appService.getProfile().subscribe(
      (data) => {
        this.getProfileResponse = true;
        this.profileName = data.firstName + " " + data.lastName;
        this.profileImage = data.profileImageUrl;
        this.profileEmail = data.email;
        this.profileInitials = data.firstName.charAt(0).toUpperCase() + data.lastName.charAt(0).toUpperCase();
        this.ciamId = data.userId;
        this.prePoulatedProfileForm(data);

      }
    )


    this.editProfileForm = this.fb.group({
      'nickName': [''],
      'socialLink': [''],
      'description': [''],
    })
  }

  public prePoulatedProfileForm(data: any): void {
    this.editProfileForm.patchValue({
      nickName: data.nickName,
      socialLink: data.socialLink,
      description: data.description
    })
  }

  public saveProfileData(): void {

    this.editProfileForm.patchValue({
      nickName: this.editProfileForm.value.nickName.trim(),
      socialLink: this.editProfileForm.value.socialLink.trim(),
      description: this.editProfileForm.value.description.trim()

    });
    if (this.editProfileForm.valid) {
      const reqObj = this.editProfileForm.value;
      reqObj.nickName = this.editProfileForm.value.nickName;
      reqObj.socialLink = this.editProfileForm.value.socialLink;
      reqObj.description = this.editProfileForm.value.description;
      this._appService.updateProfile(reqObj.nickName, reqObj.description, reqObj.socialLink, this.ciamId).subscribe(
        () => {
          this.showEditPopup = false;
          this.cancelClicked.emit(true);
          this.toastService.show(ToastType.Success, 'Update Successful ', '8%', '40%');
          setTimeout(() => {
            this.toastService.hide();
          }, 4000);
        },
        (error: HttpErrorResponse) => {
          if (error.error.code = "8005")
          this.toastService.show(ToastType.Error, 'Social Link is invalid ', '8%', '40%');
          setTimeout(() => {
            this.toastService.hide();
          }, 4000);

        }
      )
    }

  }

  closeModal() {
    this.showEditPopup = false;
    this.cancelClicked.emit(true);
  }


}