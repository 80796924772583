<div class="circuit-info-panel">
    <div class="tracks-list-container" [ngClass]="addRightOverlay ? addLeftOverlay ? 'both-overlay' : 'right-overlay' : 'left-overlay'">
        <div *ngIf="track.length > 0" id="trackListParent" (scroll)="onTrackScroll($event)" class="tracks-list" >
            <div *ngFor="let item of track; let i = index" id="path{{i}}" (click)="setSelectedTrackClass(item, i)"
                (click)="emitTrackData(item, i)" [ngClass]="selectedTrack === i ? 'track-selected':''" class="track-tab">
                <app-geo-path [name]="item.name" width="100%" height="100%" [pathColor]="item.path" [fill]="item.fill" [coordinates]="item.encodedGpsPoints">
                </app-geo-path>
            </div>
        </div>
    </div>
    <div *ngIf="selectedTrackDetails && selectedTrack != 0" class="track-cards-style">
        <app-track-cards [access_Token]="access_Token" [track]="selectedTrackDetails"></app-track-cards>
    </div>
</div>