<div *ngIf="isMyStoriesAvailable">
<div *ngIf="isStoriesResponse; else showSpinner"> 
<div  *ngIf="this.myStories.length === 0 ; else myStoriesList" >
    <app-no-experiences></app-no-experiences>
</div>
<ng-template #myStoriesList>
<ul>
    <li *ngFor="let story of myStories">
        <app-my-stories-card [story]="story" >          
        </app-my-stories-card>
    </li>
    </ul>
</ng-template>

</div>

<ng-template #showSpinner>
    <div class="spinner-border spinner">
    </div>
</ng-template>
</div>