import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, Subscription, catchError, finalize, from, mergeMap, retry, tap, throwError } from 'rxjs';
import { ToastService, ToastType } from '../services/toast.service';
import { AwsAuthService } from '../services/aws-auth.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private errorCodes: number[] = [401, 403];
  private excludedApis = ['api/gdprV2/participant'];
  constructor(private toastService: ToastService, private authAmplifyService: AwsAuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let isExcludedApi = false;
        for(let i=0; i<this.excludedApis.length; i++) {
          if(request.url.includes(this.excludedApis[i])) {
            isExcludedApi = true;
            break;
          }
        }
        if (this.errorCodes.includes(+error.status) && !isExcludedApi) {
          return this.handle401Error(request, next, error);
        } else {
          const errorMsg = this.displayServerErrorMessage(error);
          console.log('error',errorMsg)
          return throwError(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, err: any) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return from(this.authAmplifyService.currentSession()).pipe
        (mergeMap(
          (res: any) => {
            this.isRefreshing = false;
            let accessToken = res.getAccessToken()
            let jwt = accessToken.getJwtToken();
            let jwt_expires_in = accessToken.getExpiration();
            let refreshToken = res.getRefreshToken();
            let jwtRefreshToken = refreshToken.getToken();
            let idToken = res.getIdToken(); 
            let jwtIdToken = idToken.getJwtToken();
            let fullData = { "id_token": jwtIdToken, "access_token": jwt, "refresh_token": jwtRefreshToken, "expires_in": jwt_expires_in, "token_type": "Bearer" }
            this.authAmplifyService.setUserLoginStatus(true);
            this.authAmplifyService.setCurrentUserToken(fullData);
            return next.handle(this.addTokenHeader(request, jwt));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.authAmplifyService.logout();
            return throwError(() => err);
          })
        )
        ;
    } else {
      return throwError(() => err);
    }
  }

   //  Avoid to pass the real errors to client
   private displayServerErrorMessage(error: HttpErrorResponse): any {
    switch (error.status) {
      case 403: {
        return `Unauthorized access`;
      }
      case 500: {
        return `Resource not found`;
      }
      case 400: {
        return `Cluster not available for the tour `;
      }
    }
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
