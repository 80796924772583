import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, map, of} from 'rxjs';
import { MapViewResponse, SearchResponse } from '../components/interfaces/map-view.interface';
@Injectable({
  providedIn: 'root'
})
export class AppServicesService {
public api_header:any;
baseUrl: string;
public authorizationData =
  "Basic " + btoa("admin"+":"+"6Pu_C]ALY%dmLaX")

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiEndPoint;
   }
  dummyToken(){
    this.api_header = {
      headers: { Authorization: this.authorizationData, caller: 'Customer_Portal', 'user-role':'Customer_Portal' },
    };
    const url=encodeURI(`${this.baseUrl}/dummytoken`)
    return this.httpClient.get<any>(url,this.api_header);
  }

  getClusterAnnotations(token:any,guid:any){
    this.api_header = {
      headers: { Authorization: "Bearer " + token,'Content-Type': 'application/json', caller: 'Customer_Portal', 'user-role':'Customer_Portal'},
    };
    const url=encodeURI(`${this.baseUrl}/v1/tour/cluster?guid=${guid}`)
    return this.httpClient.get<any>(url, this.api_header);
  }
  getTrackAnnotations(token:any,guid:any){
    this.api_header = {
      headers: { Authorization: "Bearer " + token,caller:'Customer_Portal','user-role':'Customer_Portal','Content-Type': 'application/json'},
    };
    const url=encodeURI(`${this.baseUrl}/v1/annotation/geojson?guid=${guid}`)
    return this.httpClient.get<any>(url, this.api_header);
  }

  getAnnotationDetails(token:any,guid:any){
    this.api_header = {
      headers: { Authorization: "Bearer " + token,caller:'Customer_Portal', 'user-role':'Customer_Portal','Content-Type': 'application/json'},
    };
    const url=encodeURI(`${this.baseUrl}/v1/annotation/details?annotationId=${guid}`)
    return this.httpClient.get<any>(url, this.api_header);
  }

  getAllEmotionTour(token: any): Observable<MapViewResponse> {
    this.api_header = {
      headers: { Authorization: "Bearer " + token, caller: 'Customer_Portal', 'user-role':'Customer_Portal','Content-Type': 'application/json' },
    };
    const url = encodeURI(`${this.baseUrl}/v4/search/mapview?limitForEmotionTours=250&limitForOffRoadTours=100&limitForCircuits=250&point=48,9&radius=30000`)
    return this.httpClient.get(url, this.api_header).pipe(map((res) => {
      return res as MapViewResponse;
    }));
  }

  getMapCircuitList(
    zoomLevel: any,
    token:any,
    point:any,
    radius:any
  ): Observable<any> {
    this.api_header = {
      headers: { Authorization: "Bearer " + token,caller:'Customer_Portal', 'user-role':'Customer_Portal','Content-Type': 'application/json'},
    };
    const url = `${this.baseUrl}/v3/search/mapview?radius=${radius}&point=${point.lat},${point.lng}&limitForCircuits=250&limitForOffRoadTours=100&limitForEmotionTours=100&level=${zoomLevel}`;
    return this.httpClient.get<any>(url, this.api_header);
  }

  public gobalSearch(
    pageSize: any,
    pageNo: any,
    nameMatches: any,
    isCircuits: boolean,
    isEmotionTour: boolean,
    isOffroadzSelected: boolean,
    token:any  
  ): Observable<SearchResponse> {
    this.api_header = {
      headers: { Authorization: "Bearer " + token,caller:'Customer_Portal', 'user-role':'Customer_Portal','Content-Type': 'application/json'},
    };
    const url = encodeURI(
      `${this.baseUrl}/v1/search?pageSize=${pageSize}&pageNumber=${pageNo}&nameMatches=${nameMatches}&circuits=${isCircuits}&emotionTours=${isEmotionTour}&offRoadTours=${isOffroadzSelected}`
    );
    return this.httpClient.get(url, this.api_header).pipe(map((res) => {
      return res as unknown as SearchResponse;
    }));
  }

  getBlobImage(url: string, token:any): Observable<any> {
    let header: any = {
      headers: {
        Authorization: "Bearer " + token,
        'user-role':'Customer_Portal'
      },

      responseType: "blob",
    };

    return this.httpClient.get(url, header);
  }

  getCircuit(guid: any, token:any) {
    this.api_header = {
      headers: { Authorization: "Bearer " + token,caller:'Customer_Portal', 'user-role':'Customer_Portal','Content-Type': 'application/json'},
    };
    const url = `${this.baseUrl}/v1/circuit?guid=${guid}`;
    return this.httpClient.get<any>(url, this.api_header);
  }

  public getCiamLink(participantId: any, participantKey:any): Observable<any> {
    // const baseUrl= environment.serverUrl;
    const baseUrl = environment.serverUrl;
    const url = `${baseUrl}api/v2/customer_ciam/participant/${participantId}?participantSecretKey=${participantKey}`;
    return this.httpClient.put(url,{});

  }

  public anonymize(participantId: any, participantKey:any, token: string): Observable<any> {
    const api_header =  { Authorization: "Bearer " + token, 'Content-Type': 'application/json'};
    const baseUrl= environment.serverUrl; 
    const url = `${baseUrl}api/gdprV2/participant/${participantId}?participantSecretKey=${participantKey}`;
    return this.httpClient.put(url,{}, {headers: api_header});
  }

  /**
   * @description called when we click on the profile icon
   * @returns User profile details
   */
  public getProfile(): Observable<any> {
    const url = environment.profileUrl;
    return this.httpClient.get<any>(url);
  }

  /**
   * @description called when we save the profile information from the popup
   * @param nickName 
   * @param description 
   * @param socialLink 
   * @param ciamId 
   * @returns updated profile information
   */
  public updateProfile(nickName: string, description: string, socialLink: string, ciamId: any): Observable<any> {
    const api_header = { 'Content-Type': 'application/json', 'ciamId': ciamId};
    const baseUrl= environment.updateProfile;
    const url=`${baseUrl}/${ciamId}`;
    return this.httpClient.put(url,{nickName, description, socialLink},{headers: api_header});
  }

  setFavouriteTrackStatus(token:any,trackId:any,favStatus:string){
    const api_header =  { Authorization: "Bearer " + token, 'Content-Type': 'application/json', caller:'Customer_Portal','user-role':'Customer_Portal'};
    const baseUrl= environment.apiEndPoint; 
    const url = `${baseUrl}/v1/favorite/track`;
    return this.httpClient.post(url,{"id": trackId,
    "favorite" :favStatus}, {headers: api_header});
  }

  getUserFavTracks(token:any) {
    this.api_header = {
      headers: { Authorization: "Bearer " + token,caller:'Customer_Portal','user-role':'Customer_Portal','Content-Type': 'application/json'},
    };
    const url = `${this.baseUrl}/v1/favorite/track`;
    return this.httpClient.get<any>(url, this.api_header);
  }

  /**
   * @description called post successful CIAM login; will have customer details and 
   * event details. Called when we reach the link-participant-id page
   * @param participantId 
   * @param participantKey 
   * @returns Participant details and event details
   */
  public getParticipantInfo(participantId: any, participantKey: any): Observable<any>{
    const baseUrl = environment.serverUrl
    const url= `${baseUrl}api/v2/customer_ciam/participant/${participantId}?participantSecretKey=${participantKey}`;
    return this.httpClient.get(url);
  }

} 
