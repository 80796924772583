// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  enableSummaryButton: false,
  enableTourLines: true,
  setAccurateFade: true,
  isEnabled: true,
  production: false,
  apiEndPoint: "https://amgmaa-dev.mercedes-benz.com/aca",
  serverUrl: 'https://api.dev.business.stories.me/',
  issuer: 'https://ciam-auth.dev.stories.me/oauth2/authorize',
  tokenURL: 'https://ciam-auth.dev.stories.me/oauth2/token',
  revokeTokenURL: 'https://ciam-auth.dev.stories.me/oauth2/revoke',
  redirectUri: 'http://localhost:4200/race/login',
  raceSharingPageUrl: 'https://dev.stories.me/race',
  domainUrl: 'https://dev.stories.me/',
  logoutUrl: 'https://ciam-auth.dev.stories.me/logout?client_id=4up6munqeat1nergum7bgi3s4e&logout_uri=http://localhost:4200/race/logout',
  clientId: '4up6munqeat1nergum7bgi3s4e',
  responseType: 'code',
  scope: 'email+openid',
  apikey: "WIDfMYNRavPaQWkJSnq3ieTrVAO36N68-LTIFj0VLyI",
  detailsUrl: "http://localhost:4202",
  myStoriesUrl: "https://apis.dev.business.stories.me/aes/acp/v1/stories",
  updateProfile: "https://apis.dev.business.stories.me/aus/acp/v1/users",
  profileUrl: "https://apis.dev.business.stories.me/aus/acp/v1/user",
  supported_language: ['en-GB', 'en', 'de', 'de-DE', 'fr-FR', 'es-ES', 'cs-CZ', 'ko-KR', 'it-IT'],
  amplifyConfig: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_sZ7ypXDCd',
    userPoolWebClientId: '4up6munqeat1nergum7bgi3s4e',
    oauth: {
      domain: 'ciam-auth.dev.stories.me',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: 'https://dev.stories.me/login',
      redirectSignOut: 'https://id-int.mercedes-benz.com/idp/startSLO.ping?TargetResource=https%3A%2F%2Fdev.stories.me%2Flogout',
      responseType: 'code',
    },
  },
  featureFlagLink:'../../assets/json/feature-flags.json',

  feature: {
    enableOffTour: true,
    isMyStoriesAvailable: true,
    isfooterAvailable: true,
    enableProfile: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.