import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProdEnabledGuard implements CanActivate {
  enabledPath = [
    'coming-soon',
    'login',
    'anonymize',
    'link-participant-id',
    'processing',
    'verify',
    'my-race-details'
    
  ];

  constructor(private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!environment.production) {
      return true;
    } else if(this.enabledPath.includes(state.url)) {
      return true;
    } else {
      this.router.navigateByUrl('coming-soon');
      return false;
    }
  }
  
}
