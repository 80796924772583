<div class="rectangle">
    <div class="img-reg">
      <div class="image"  style="background-image: url({{banner?banner:defaultBanner}});">
      </div>
    </div>
    <div class="info-center">
        <span class="Your-race-data-is-in">
            <p>{{ subMessage }}</p>
            <p>{{ subMessage1 }}</p>
        </span>
    </div>

    <div class="info-panel-bottom" >

        <span > Track Pace Challenge Goodwood </span>
        <h2 class="Best-Time-Challenge">Best Time Challenge 1</h2>
        <div class="rowele">
          <div class="circuit tag-item" >
            <span > Track Pace </span>
          </div>
          <div class="track tag-item">
            <span > Goodwood Track </span>
          </div>
          <div class="track tag-item"> 
            <span > 23.05.2023 </span>
          </div>
        </div>
          <div class="rowele">
          <div class="track  tag-item">
            <span > Mercedes - AMG E63 S 4MATIC+ </span>
          </div>
          <div class="track  tag-item" >
            <span > #Hockenheimring GP </span>
          </div>
        </div>
         
    </div>
</div>

<!-- </div>
</div>
 *ngif="getCiamResponse; else SpinnerLoads"   -->

