import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyStoriesService {
  
  token = localStorage.getItem('access_token');
  baseUrl = environment.myStoriesUrl;


  constructor(private _httpClient: HttpClient) { }

  fetchStories(lat: number, long: number, pageNumber: number, pageSize: number): Observable<any> {
    // const response = require('../../../src/test.json')
    // return of (response);
    const url = `${this.baseUrl}?lat=${lat}&long=${long}&page_number=${pageNumber}&page_size=${pageSize}`
    return this._httpClient.get(url)
  }


}
