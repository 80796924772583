import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-ciam-landing',
  templateUrl: './ciam-landing.component.html',
  styleUrls: ['./ciam-landing.component.scss']
})
export class CiamLandingComponent implements OnInit {
@Input() public message: string = "It's too quite here...";
@Input() public subMessage: string = "Your race data is in the works... ";
@Input() public subMessage1: string=" and we'll give you a shout when it's all set to go";
translationData = [];
banner = "";
defaultBanner= "../../../assets/images/banner.png";
  constructor(private translationService: TranslationService) {

   }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
  }

}
