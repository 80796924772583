<div class="ciamImage ">
    <div class="layer">
        <div class="info-center">
            <div class="message-position">
                <h1>{{'CP_LM_NoExperience_message'| customTranslation: translationData }}</h1>
            </div>
            <div class="subMessage">
                <p>{{'CP_LM_NoExperience_subMessage' | customTranslation: translationData }}</p>
            </div>
        </div>

    </div>
</div>
