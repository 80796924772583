import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-track-details',
  templateUrl: './track-details.component.html',
  styleUrls: ['./track-details.component.scss']
})
export class TrackDetailsComponent implements OnInit {

  @Input() public track: any = [];
  @Input() public access_Token:any;
  @Output() public sendTrackData = new EventEmitter<any>();

  isAnchorDisable = true;
  translationData = [];
  pathColor = '#fff';
  fill = "#000";
  selectedTrack:any = 0;
  selectedTrackDetails:any = null
  addRightOverlay:any = true;
  addLeftOverlay:any = false;


  constructor(private translationService: TranslationService) { }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.setSelectedTrackClass({},0);
  }

  emitTrackData(data:any, index:any){
    data.index = index;
    this.sendTrackData.emit(data)
  }

  onTrackScroll(event:any){
    let lastEle = document.getElementById('path'+(this.track.length-1));    
    if(lastEle && (lastEle?.getBoundingClientRect().left < (lastEle?.scrollWidth * 3))){
      this.addRightOverlay = false;
    } else {
      this.addRightOverlay = true;
    }
    let firstEle = document.getElementById('path0');
    let scrolledValue = firstEle?.getBoundingClientRect().x
    if(scrolledValue && scrolledValue < 0) {
      this.addLeftOverlay = true;
    } else{
      this.addLeftOverlay = false;
    }
  }

  setSelectedTrackClass(trackData:any, index:any){
    this.selectedTrackDetails = trackData;
    this.selectedTrack = index;
    this.track?.forEach((item:any, i:any) => {
      if(i === index){
        item.fill = "#666767";
        item.path = "#ffff"
      }else{
        item.fill = "none";
        item.path = "#767676";
      }
    });
  }

}
