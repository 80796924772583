import { environment } from 'src/environments/environment';
import { Component, HostListener, OnInit } from '@angular/core';
import { ESTORY_ITEM_TYPE, MystoriesResponse, Story } from '../interfaces/myStories.interface';
import { MyStoriesService } from 'src/app/services/my-stories.service';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-stories-list',
  templateUrl: './my-stories-list.component.html',
  styleUrls: ['./my-stories-list.component.scss']
})
export class MyStoriesListComponent implements OnInit {
  coordinates = {
    lat: 52.520007,
    long: 13.404954,
  }
  totalNoOfPages = 0;
  public page: any = 0;
  momentList: any = [];
  myStories: Story[] = [];
  isStoryEmpty: boolean= false;
  isStoriesResponse: boolean = false;
  isMyStoriesAvailable =  environment.feature.isMyStoriesAvailable;

  @HostListener("window:scroll", ['$event'])
  onScroll(_event?: Event): void {
    const footer = document.getElementById('footer-container');
    if (footer) {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight - footer.offsetHeight) {
        if ( this.myStories.length < 50 && this.page < this.totalNoOfPages - 1) {
          this.loadMoreEventsButton();
        }
      }
    }
  }
  constructor(
    private _myStoriesService: MyStoriesService,
    private awsAuth: AwsAuthService,
    private router:Router,
  ) { }
  ngOnInit(): void {
    let userLoggedIn = localStorage.getItem('useLogged');
    if (!userLoggedIn) {
      const selectedLanguage = localStorage.getItem('language');
      localStorage.setItem('routeUrl', '/'+selectedLanguage+'/stories');
      this.awsAuth.signIn();
    } 
    this.getMoments();
  }
   public loadMoreEventsButton(): void {
    this.page += 1;
    this.getMoments();
  }

  public getMoments() : void{  
    this._myStoriesService.fetchStories(this.coordinates.lat, this.coordinates.long, this.page, 10)
      .subscribe((response: MystoriesResponse) => {
        this.totalNoOfPages = response.total_pages;
        this.isStoriesResponse = true;
        this.myStories.push(...response.stories);
      })
  }
}
