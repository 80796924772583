import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from "@angular/common";
import { Auth } from 'aws-amplify';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { TranslationService } from 'src/app/services/translation.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuSelected = false;
  subMenu1Selected = false;
  subMenu2Selected = false;
  loggedInUser = true;
  changeText = false;
  currentUser: any;
  showHeader:any = true;
  mySubscription: any;
  auth = Auth;
  userLoggedIn: any;
  logoutUrl: string = environment.amplifyConfig.oauth.redirectSignOut;
  selectedCategory:any = '/explore'
  logstatus: any;
  toggleHeader:any = environment.isEnabled;
  translationData = [];
  language = this.languageService.getLanguage();
  showMenu = false;
  selectedLang:any;
  showBottomMenu: any;
  screenWidth: any;
  isHeaderMin = false;
  signedIn = false;
  isProfileClicked = false;
  public isHeadingAvailable = environment.feature.isMyStoriesAvailable;
  videoplayed: any = 'false';
  featureFlags: any;
  isProd = environment.production;
  public showMyProfileLink: boolean = false;
  public showLoginLink: boolean = false;
  languageToDisplay: string = 'Deutsch';
  constructor(private router: Router, private location: Location, 
    private awsAuth: AwsAuthService, private translationService: TranslationService, 
    private _actRoute: ActivatedRoute, private _router: Router,
    private languageService: LanguageService) {   
    let selectedLanguage = localStorage.getItem('language');
    if (!selectedLanguage) {
      selectedLanguage = 'en-GB';
    }
    this.selectedLang = selectedLanguage;
    // This is done for CIAM related pages, where we have to show the header dynamic language.
    // This is set based on participant locale we get from API
    this.languageService.participantLocale.subscribe((locale: any) => {
      this.getUserSelectedLanguage(locale);
    });
	}

  ngOnInit() {
    this.language = this.languageService.getLanguage();
    this.featureFlags = this.translationService.featureFlags;
    this.getUserSelectedLanguage(this.language);
    let videoStatus = sessionStorage.getItem('videoplayed');
    if(videoStatus){
      this.videoplayed = videoStatus;      
    }
    if(this.videoplayed === 'true'){
        this.isHeaderMin = true;
    }
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('event.url',event.url)
        if(event.url === '/temp-login' || event.url === '/' || event.url === '/logout'){
          this.showHeader = false;
        }
        else {
          this.showHeader = true;
        }
        let urlArray = event.url.split('/')
        console.log(urlArray)
        this.selectedCategory = '/'+urlArray[urlArray.length - 1]
      } 
    });
    this.userLoggedIn = localStorage.getItem('useLogged')
    if(this.userLoggedIn && this.userLoggedIn.length > 0){
      this.currentUser = true;
    } else{
      this.currentUser = false;
    }
    this.showMyProfileHeaderOnLogin();
    this.logstatus = this.awsAuth.getLoginStatus().subscribe((status:any) =>{
      this.currentUser = status
      this.showMyProfileHeaderOnLogin();
    })
    setTimeout(()=>{
      this.isHeaderMin = true
    }, 3600)
  }

  async logout() {
    localStorage.setItem('routeUrl', 'home');
    localStorage.removeItem('useLogged');
    localStorage.removeItem('routeUrl');
    this.logstatus.unsubscribe();
    await this.auth.signOut()

      .then((data) => {

        setTimeout(()=> {

          window.open(this.logoutUrl, "_self");

        }, 1000);

      })  

  }



  showDropMenu(){
    this.showMenu = true;
  }

  vidEnded(){
    sessionStorage.setItem('videoplayed','true')
  }

  hideMenuInMobile(){
    this.screenWidth = window.innerWidth;  
    if(this.screenWidth < 1024){
      this.showMenu = false;
    }
  }

  hideDropMenu() {
    this.showMenu = false;
  }

  selectMenu() {
    this.menuSelected = !this.menuSelected;
  }

  showDropDownInMobile(){
    this.showBottomMenu = !this.showBottomMenu;
  }



  goToHome(){
    this.location.go('/'+this.language+'/explore');
    window.location.reload();
    localStorage. removeItem('filter');

  }  

  /**
   * @description If user lang is english but broswer language diff and is part of our supported lang - that lang.
   * If user lang and and browser default is Engligh -> German
   * If lang is not supported - then 404 
   * @param locale 
   */
  public getUserSelectedLanguage(participantLang: string) {
    let urlLang = participantLang;
    const supportedLocales: any = {
      'en-GB': 'English',
      'de-DE': 'Deutsch',
      'de': 'Deutsch',
      'ko-KR': 'Korean',
      'ko': 'Korean',
      'fr-FR': 'French',
      'fr': 'French',
      'es-ES': 'Spanish',
      'es': 'Spanish',
      'en': 'English',
      'en-US': 'English',
      'cs-CZ': 'Czech',
    }
    const fullURL = window.location.href.split('/');
    const langIndex = fullURL.findIndex(part => environment.supported_language.includes(part));
    let isEngilsh = (lang: string) => lang && lang.includes('en') ? true : false;
    if (langIndex !== -1) {
      urlLang = fullURL[langIndex];
    }
    const locale = isEngilsh(urlLang) ? participantLang : urlLang;
    const selectedLang = (url: string) => supportedLocales[url];
    this.languageToDisplay = !isEngilsh(locale) ? selectedLang(locale) : selectedLang(isEngilsh(this.language) ? 'de-DE' : this.language);
  }

  public switchLanguage(language:string) {
    const supportedLocales: any = {
      'English': 'en-GB',
      'Deutsch': 'de-DE',
      'Korean': 'ko-KR',
      'French': 'fr-FR',
      'Spanish': 'es-ES',
      'Czech': 'cs-CZ'
    }
    const selectedLang = (url: string) => supportedLocales[url] ? supportedLocales[url] : 'de-DE';
    const fullURL = window.location.href.split('/');
    const langIndex = fullURL.findIndex(part => environment.supported_language.includes(part));
    fullURL[langIndex] = selectedLang(language);
    localStorage.setItem('language', selectedLang(language));
    window.location.href = fullURL.join('/');
    this.selectedLang = language;
  }

  addLanguage(language:any) {
    localStorage.setItem('language', language);
    this.selectedLang = language;
    const fullURL = window.location.href.split('/');
    fullURL[3] = language;
    window.location.href = fullURL.join('/');
  }

  triggerLogin() {
    this.awsAuth.signIn();
    this.signedIn = true;
  }

  /**
   * @description Non-Prod:- Method to handle navigation to my stories page
   * We need to go to CIAM login page if user is not logged in
   * Upon logging in, I should be redirected to the same page I was last on
   * Prod:- Method to handle navigation to home page and no Login/Explore buttons visible to user
   */
  public handleMyStoriesNavigationClick(): void {
    this.showBottomMenu = false;
    switch (true) {
      case this.isProd:
        this.router.navigateByUrl(`${this.language}/home`);
        break;
      case this.currentUser && this.isHeadingAvailable:
        this.router.navigateByUrl(`${this.language}/stories`);
        break;
      case !this.currentUser && this.isHeadingAvailable:
        localStorage.setItem('routeUrl', '/'+this.selectedLang+'/stories');
        this.triggerLogin();
        break;
      case !this.currentUser && !this.isHeadingAvailable:
        this.goToHome();
        break;
      default:
        this.router.navigateByUrl(`${this.language}/home`);
        break;
    }
  }

  /**
   * @description Method to show/hide my profile and login links in header
   * called either on login or on page load
   */
  public showMyProfileHeaderOnLogin(): void {
    if (this.isProd) {
      this.showMyProfileLink = false;
      this.showLoginLink = false
    } else if (!this.currentUser) { 
      this.showMyProfileLink = false;
      this.showLoginLink = true;
    } else if (this.currentUser && this.isHeadingAvailable) {
      this.showMyProfileLink = true;
      this.showLoginLink = false;
    }
  }
 
  profileClicked(){
    if(this.featureFlags.general.enableMyStories)
    this.isProfileClicked=true;
    
  }
  cancelClicked(event:any){
    if(event){
      this.isProfileClicked=false
    }
  }

  public navigateToProviderPage(): void {
    this._router.navigate(['/' + this.selectedLang + '/provider']);
  }

  public navigateToPrivacyPage(): void {
    this._router.navigate(['/' + this.selectedLang + '/privacy']);
  }

}


