import { Component, Input, OnInit } from '@angular/core';
import { AppServicesService } from 'src/app/services/app-services.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-track-cards',
  templateUrl: './track-cards.component.html',
  styleUrls: ['./track-cards.component.scss']
})
export class TrackCardsComponent implements OnInit {

  @Input() public track: any;
  @Input() public access_Token:any;
  translationData = [];
  isAnchorDisable = true;

  constructor(private translationService: TranslationService, private appService:AppServicesService) { }

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    // this.getAllFavTracks();
  }

  // getAllFavTracks(){
  //   this.appService.getUserFavTracks(this.access_Token).subscribe((response:any) => {
  //     console.log('response all fav', response)
  //   })
  // }


  setUnsetFavourite(status:string){
    this.appService.setFavouriteTrackStatus(this.access_Token , this.track.guid, status).subscribe((response:any) => {
      console.log('response fav', response)
    })
  }

}
